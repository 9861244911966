// This is called a "splat route" and as it's in the root `/app/routes/`
// directory, it's a catchall. If no other routes match, this one will and we
// can know that the user is hitting a URL that doesn't exist. By throwing a
// 404 from the loader, we can force the error boundary to render which will
// ensure the user gets the right status code and we can display a nicer error
// message for them than the Remix and/or browser default.

import { useLocation } from "@remix-run/react";
import { ArrowLeft } from "lucide-react";
import { GeneralErrorBoundary } from "~/components/error-boundary";
import { LinkButton } from "~/components/ui/buttons";

export async function loader() {
  throw new Response("Not found", { status: 404 });
}

export default function NotFound() {
  // due to the loader, this component will never be rendered, but we'll return
  // the error boundary just in case.
  return <ErrorBoundary />;
}

export function ErrorBoundary() {
  const location = useLocation();

  return (
    <GeneralErrorBoundary
      statusHandlers={{
        404: () => (
          <div className="my-[16px] flex flex-col gap-y-6 px-[24px] font-normal text-gray-600">
            <div className="flex flex-col gap-y-3">
              <h1>Unable to find page:</h1>
              <pre className="text-body-lg whitespace-pre-wrap break-all">
                {location.pathname}
              </pre>
            </div>
            <LinkButton to={"/"}>
              <ArrowLeft className="mr-4" /> Go back home
            </LinkButton>
          </div>
        ),
      }}
    />
  );
}
